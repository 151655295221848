import {useMutation} from "react-query";
import Alert from "react-s-alert";

import {http} from "../../../services/api/restApiClient";
import {PccFacilityMappingModel} from "../../../utils/types/facility";

type Params = {
  facilityId: string;
  pccFacilityMapping: PccFacilityMappingModel;
};

export const usePccFacilityMappingMutation = () =>
  useMutation(
    (params: Params) =>
      http.put(`/facilities/${params.facilityId}/pcc-mapping`, params.pccFacilityMapping),
    {
      onSuccess: () => {
        Alert.success("PointClickCare mapping updated.");
      },
      onError: data => {
        Alert.error(
          "An error occurred while updating PointClickCare connection data",
        );
      },
    },
  );
