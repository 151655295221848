import {useQuery} from "react-query";
import {http} from "../../../services/api/restApiClient";
import {PccFacilityMappingModel} from "../../../utils/types/facility";

type Params = {
  facilityId: string;
};

export const usePccFacilityMappingQuery = ({facilityId}: Params) =>
  useQuery(
    ["getPccFacilityMapping", facilityId],
    async ({queryKey: [_, facilityId]}) => {
      const {data} = await http.get<PccFacilityMappingModel>(`/facilities/${facilityId}/pcc-mapping`);
      return data;
    },
    {
      cacheTime: 0,
    },
  );
