import {
  SimpleFacilityDto,
  CreateFacilityDto,
  FacilityFeatureFlagsDto,
} from "@reside/reside-api-app";
import {http} from "./api/restApiClient";

import {OrganizationsService} from "./OrganizationsService";
import {PccIntegrationStatus} from "./PccService";
import {UpdateFacilityTemplateModel} from "../utils/types/facility";

export const facilityHasPccTurnedOn = (facility: Facility) =>
  facility?.pccIntegrationStatus === PccIntegrationStatus.PccTurnedOn;

export const facilityHasPccUploadActive = (facility: Facility) =>
  Boolean(facility?.pccUploadActive);

export const getFacilityFeatureFlags = (facility: Facility) => ({
  facilityHasPccTurnedOn: facilityHasPccTurnedOn(facility),
  facilityHasPccUploadActive: facilityHasPccUploadActive(facility),
  facilityHasOrgAdminOnlyDeleteAdmissions:
    !!facility.featureFlags?.[
      FacilityFeatureFlagsDto.FeatureFlagsEnum.ORG_ADMIN_ONLY_DELETE_ADMISSIONS
    ],
});

export type Facility = SimpleFacilityDto;

export const FacilityService = {
  findByOrganization: (organizationId: string) =>
    OrganizationsService.getOrganizationFacilities(organizationId),

  create: (facility: CreateFacilityDto) =>
    http.post("/facilities", facility),

  patch: (facilityId: string, updateModel: UpdateFacilityTemplateModel) =>
    http.patch(`/facilities/${facilityId}`, updateModel),
};
