import {useMutation} from "react-query";
import Alert from "react-s-alert";
import {get} from "lodash";

import {http} from "../../../services/api/restApiClient";

type Params = {
  facilityId: string;
  onSuccess: () => void;
};

export const useDeleteFacilityMutation = () =>
  useMutation(
    ({facilityId}: Params) =>
      http.delete(`/facilities/${facilityId}`),
    {
      onError: response => {
        Alert.error(
          get(
            response,
            "data.message",
            "Facility can be deleted only in case there are no admissions left in Pre-Flight, In-Progress, Signed or Approved swim lanes.",
          ),
        );
      },
      onSuccess: (_, {onSuccess}) => {
        onSuccess();
      },
    },
  );
