import {PccFacilityMappingModel} from "../../../../../utils/types/facility";

/**
 * Checks if a PCC facility mapping object contains data. Use it to prevent setting the initial values to empty object which is returned from the API in absence of data.
 *
 * @param {PccFacilityMappingModel} pccFacilityMapping - The PCC facility mapping object to check.
 * @returns {boolean} Returns `true` if the object contains data, `false` otherwise.
 *
 */
export const hasPccMappingData = (
  pccFacilityMapping: PccFacilityMappingModel,
): boolean => Object.keys(pccFacilityMapping ?? {}).length > 0;
