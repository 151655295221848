import {useQuery} from "react-query";

import {buildResponseErrorMessage} from "../../../services/api/errorHelpers";
import {http} from "../../../services/api/restApiClient";

export const useFacilitySettingsQuery = ({facilityId}: {facilityId: string}) =>
  useQuery(
    ["facilitySettings", facilityId],
    async ({queryKey: [_, facilityId]}) => {
      const {data} = await http.get<any>(`/facilities/${facilityId}/settings`);
      return data;
    },
    {
      onError: async resp => {
        await buildResponseErrorMessage(
          resp,
          "Failed to fetch facility settings. Please, try again later.",
        );
      },
    },
  );
