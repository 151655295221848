import {PccFacilityMappingModel} from "../../../../../utils/types/facility";

/**
 * Initial values for the form when PCC is disabled.
 *
 * @constant
 * @type {Object}
 * @default
 */
export const INITIAL_VALUES_FOR_PCC_DISABLED_FACILITY: Omit<
  PccFacilityMappingModel,
  "defaultDocumentCategoryIdentifier"
> = {
  pccFacilityId: "" as unknown as number,
  pccOrganizationId: "",
};
